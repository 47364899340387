class QuantityCounter extends HTMLElement {
	constructor() {
		super();

		let minusButton = document.createElement("button");
		minusButton.type = "button";
		minusButton.innerHTML = "<svg><use xlink:href='#icon-arrow-left'></use></svg>";
		this.appendChild(minusButton);
		this.minusButton = minusButton;

		let input = document.createElement("input");
		input.type = "number";
		input.value = 1;
		input.name = "quantity";
		input.min = 1;
		input.max = 10;
		input.step = 1;
		input.required = true;
		this.appendChild(input);
		this.inputEl = input;

		let addButton = document.createElement("button");
		addButton.type = "button";
		addButton.innerHTML = "<svg><use xlink:href='#icon-arrow-right'></use></svg>";

		this.appendChild(addButton);
		this.addButton = addButton;

		this.classList.add("quantity-counter");
	}

	connectedCallback() {
		this.minusButton.addEventListener("click", _ => {
			this.inputEl.stepDown();
			this.updateCartButton();
		});
		this.addButton.addEventListener("click", _ => {
			this.inputEl.stepUp();
			this.updateCartButton();
		});
		this.cartButton = document.querySelector("button[is='add-to-cart-button']");
	}

	updateCartButton() {
		this.cartButton.setAttribute("quantity", this.inputEl.value);
	}
}

window.customElements.define("quantity-counter", QuantityCounter);
